.mail-list-wrapper {
    width: 380px;
    max-width: 380px;
    height: calc(100vh - 90px);
    overflow: auto;
    overflow-x: hidden;
}

.mail-card-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 380px;
    cursor: pointer;
}

.mail-card-wrapper:hover {
    background-color: #efefef;
}

.mail-card-wrapper-active {
    background-color: #cbcbcb;
}
.mail-card-from-row {
    color: #434F58;
    font-size: 0.9rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mail-card-subject-row {
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.mail-list-header {
    padding: 10px;
    border-bottom: 1px solid #434F58;
    margin: 0 !important;
}

.scroll-style-1::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.scroll-style-1::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}

.scroll-style-1::-webkit-scrollbar-thumb
{
    background-color: #6b6767;
}

.mail-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mail-header-wrapper {
    display:flex;
    gap: 10px;
    padding: 10px 0;
}

.mail-body-wrapper {
    height: calc(100vh - 245px);
    overflow: auto;
    overflow-X: hidden;
}

.mail-page-wrapper {
    background: white;
    border-radius: 10px;
    display: flex;
    gap: 10px;
}
