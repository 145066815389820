.drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F3F6F9;
    padding: 10px 24px !important;
    color: #333333;
    font-size: 20px;
}

.drawer-content {
    padding: 20px;
}

.profile-img-wrapper {
    position: relative;
}

.profile-img-dropzone {
    position: relative;
    width: 150px;
    height: 150px;
    background-color: #EFEFEF;
    border-radius: 100%;
    border: 0;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.profile-img-recommended-text-wrapper {
    color: #5B5B5B;
    text-align: center;
    width: 130px;
    margin: 0 auto;
}

.link-wrapper {
    font-size: 18px;
    font-weight: 500;
    color: #1A84E5;
}

.profile-label {
    width: 150px;
    font-size: 18px;
    font-weight: 100;
    height: 40px;
    color: #333333;
}

.section-separator {
    border: 1px solid #DEDEDE;
    margin: 22px 0;
}

.file-detail-main-file-name {
    position: relative;
    font-weight: 100;
    font-size: 10px;

    &::after {
        content: " ";
        border-bottom: 1px dashed grey;
        position: absolute;
        bottom: -10px;
        width: 100%;
        left: 0;
    }
}

.file-path {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
}

.file-action-button-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 5px;
    float: right;
}

.file-detail-content-wrapper {
    display: flex;
    width: 100%;
    gap: 20px;
}

.file-detail-content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    div {
        height: 24px;
    }

    .label {
        font-family: 'Roboto';
        color: #333333;
        font-weight: 100;
    }

    .designer-detail {
        font-weight: 100;
        color: black;
        cursor: pointer;
        display: flex;
        position: relative;

        .designer-avatar {
            position: absolute !important;
            top: 0;
            right: -35px;
        }
    }
}

.designer-detail-info-table-header-wrapper {
    th {
        padding: 10px;
        text-align: left;
    }

    td {
        padding: 10px;
        background: white;
        border-right: 1px solid #E6E6E6;
        color: #1A84E5;
    }
}

.designer-info {
    white-space: nowrap;
}

.file-url {
    color: #1A84E5;
    font-weight: 500;
    cursor: pointer;
}

.review-table-file-label {
    font-family: 'Roboto';
    color: #333333;
    font-weight: 100;
    font-size: 15px;
    margin-bottom: 10px;
}

.empty-list-message {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 24px;
    color: #c4c5c7;
}

.forward-body-container {
    height: calc(100vh - 430px);
    overflow: auto;
}
